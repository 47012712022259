import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {IBusinessplanRootObject} from '@app/interfaces';
import {IdeastartuplistControllerService} from '@app/shared/sdk/api/ideastartuplistController.service';
import {NzCardModule} from "ng-zorro-antd/card";
import {TranslateModule} from "@ngx-translate/core";
import {PlateComponent} from '../plate/plate.component';
import {AnaliticsTableRow} from './analitics-table.interface';
import {NgIf} from "@angular/common";
import { AccelerationTrendChartComponent } from "../../../businessplan-item/pnl-sections/acceleration/acceleration-trend-chart/acceleration-trend-chart.component";
import { Subject, takeUntil } from 'rxjs';
import * as TableHeaders from './table-headers';


@Component({
    selector: 'app-main-assumptions',
    standalone: true,
    imports: [NzCardModule, TranslateModule, PlateComponent, NgIf, AccelerationTrendChartComponent],
    templateUrl: './main-assumptions.component.html',
    styleUrl: './main-assumptions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MainAssumptionsComponent implements OnInit {
    @Input() ideaId: number;
    private readonly unsubscribe$: Subject<void> = new Subject();
    public plan: IBusinessplanRootObject;
    private currency: string;
    private timeUom: string = " months";
    private percentageUom: string = " %";
    public tHeads = TableHeaders;

    public portfolioTableData: AnaliticsTableRow[] | undefined;
    public seasonalityTableData: AnaliticsTableRow[] | undefined;
    public headcountTableData: AnaliticsTableRow[] | undefined;
    public discountTableData: AnaliticsTableRow[] | undefined;
    public otherCogsTableData: AnaliticsTableRow[] | undefined;
    public investmentsTableData: AnaliticsTableRow[] | undefined;
    public marketingTableData: AnaliticsTableRow[] | undefined;
    public operatingExpencesTableData: AnaliticsTableRow[] | undefined;
    public rndTableData: AnaliticsTableRow[] | undefined;
    public otherIncomeAndLossTableData: AnaliticsTableRow[] | undefined;

    public accelerationChartData: any;
    public accelarationPeriods: number[] = [];

    ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }

    constructor(private ideaService: IdeastartuplistControllerService,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.ideaService.ideastartuplistControllerFindById(this.ideaId)
        .pipe(takeUntil(this.unsubscribe$)).subscribe((idea) => {
            this.plan = JSON.parse(idea.ideabusinessplansetup);
            this.currency = " " + this.plan.businessplanSetting.currency;
            this.accelarationPeriods = Array.from({length: this.plan.businessplanSetting.periods}, (v, k) => k + 1)
            this.accelerationChartData = this.plan.acceleration;

            this.generateAllTables();

            this.cdr.detectChanges();
        });
    }


  private generateAllTables() {
    this.portfolioTableData = (this.generatePortfolioAnalitics().length === 0 ? undefined : this.generatePortfolioAnalitics());
    this.seasonalityTableData = (this.generateSeasonalityAnalitics().length === 0 ? undefined : this.generateSeasonalityAnalitics());
    this.headcountTableData = (this.generateHeadcountAnalitics().length === 0 ? undefined : this.generateHeadcountAnalitics());
    this.discountTableData = (this.generateDiscountAnalitics().length === 0 ? undefined : this.generateDiscountAnalitics());
    this.otherCogsTableData = (this.generateOtherCogsAnalitics().length === 0 ? undefined : this.generateOtherCogsAnalitics());
    this.investmentsTableData = (this.generateInvestmentsAnalitics().length === 0 ? undefined : this.generateInvestmentsAnalitics());
    this.marketingTableData = (this.generateMarketingAnalitics().length === 0 ? undefined : this.generateMarketingAnalitics());
    this.operatingExpencesTableData = (this.generateOperatingExpencesAnalitics().length === 0 ? undefined : this.generateOperatingExpencesAnalitics());
    this.rndTableData = (this.generateRndAnalitics().length === 0 ? undefined : this.generateRndAnalitics());
    this.otherIncomeAndLossTableData = (this.generateOtherIncomeAndLossAnalitics().length === 0 ? undefined : this.generateOtherIncomeAndLossAnalitics());
  }

  generatePortfolioAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.portfolio.productsServices.forEach((product, index) => {
            if (index == 0) {
                return;
            }
            let row = [];
            row.push(product.brand);
            row.push(product.cost.toString() + (this.currency || ""));
            row.push(product.price.toString() + (this.currency || ""));
            row.push(product.UOM);
            row.push(product.totalUnits);
            row.push(product.customers);
            row.push(product.ordersMonthPerCustomer);
            tableData.push({title: product.name.split("##")[1], values: row});
        });
        return tableData;
  }
  
  generateSeasonalityAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.seasonality.forEach((season: any, index) => {
            let row = [];
            season.seasonalityIndex.forEach((value) => {
                row.push(value * 100.0 + " %");
            });
            tableData.push({title: season.name.split("##")[1], values: row});
        });
        return tableData;
  }
  
  generateHeadcountAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.headcountAndPayroll.headcount.forEach((head) => {
            let row = [];
            row.push(head.numOfPeople);
            row.push(head.beginningMonth + this.timeUom);
            row.push(head.endingMonth  + this.timeUom);
            row.push(head.netSalary.toString() + (this.currency || ""));
            row.push(head.monthOfService + this.timeUom);
            tableData.push({title: head.jobTitle, values: row});
        });
        return tableData;
  }
  
  generateDiscountAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.discountsAndReturns.forEach((discount, index) => {
            let row = [];
            row.push(discount.staticMonthlyNumber + (this.currency || ""));
            row.push(discount.percentage * 100.0 + this.percentageUom);
            row.push(discount.startMonth + this.timeUom);
            row.push(discount.endMonth + this.timeUom);
            tableData.push({title: discount.description, values: row});

        });
        return tableData;
  }
  
  generateOtherCogsAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.cogs.forEach((cog) => {
            let row = [];
            row.push(cog.staticMonthlyNumber +(this.currency || ""));
            row.push(cog.percentage * 100.0 + this.percentageUom);
            row.push(cog.vendor);
            row.push(cog.startMonth  + this.timeUom);
            row.push(cog.endMonth + this.timeUom);
            tableData.push({title: cog.description, values: row});

        });
        return tableData;
  }
  
  generateInvestmentsAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.investmentAndCapex.forEach((investment) => {
            let row = [];
            row.push(investment.totalValuePrice.toString() + (this.currency || ""));
            row.push(investment.depreciationMonths + this.timeUom);
            row.push(investment.startMonth  + this.timeUom);
            row.push(investment.endMonth  + this.timeUom);
            tableData.push({title: investment.description, values: row});
        });
        return tableData;
  }
  
  generateMarketingAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.marketing.forEach((marketing) => {
            let row = [];
            row.push(marketing.staticMonthlyNumber.toString() + (this.currency || ""));
            row.push(marketing.percentage * 100.0 + this.percentageUom);
            row.push(marketing.vendor);
            row.push(marketing.startMonth + this.timeUom);
            row.push(marketing.endMonth + this.timeUom);
            tableData.push({title: marketing.description, values: row});

        });
        return tableData;
  }
  
  generateOperatingExpencesAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.opex.forEach((opex) => {
            let row = [];
            row.push(opex.staticMonthlyNumber.toString() + (this.currency || ""));
            row.push(opex.percentage * 100.0 + this.percentageUom);
            row.push(opex.vendor);
            row.push(opex.startMonth + this.timeUom);
            row.push(opex.endMonth + this.timeUom);
            tableData.push({title: opex.description, values: row});

        });
        return tableData;
  }
  
  generateRndAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.rnd.forEach((rnd) => {
            let row = [];
            row.push(rnd.staticMonthlyNumber.toString() + (this.currency || ""));
            row.push(rnd.percentage * 100.0 + this.percentageUom);
            row.push(rnd.vendor);
            row.push(rnd.startMonth + this.timeUom);
            row.push(rnd.endMonth + this.timeUom);
            tableData.push({title: rnd.description, values: row});

        });
        return tableData;
  }
  
  generateOtherIncomeAndLossAnalitics(): AnaliticsTableRow[] {
        let tableData: AnaliticsTableRow[] = [];
        this.plan.otherOperatingIncomeLoss.forEach((otherOperatingIncomeLoss) => {
            let row = [];
            row.push(otherOperatingIncomeLoss.staticMonthlyNumber.toString() + (this.currency || ""));
            row.push(otherOperatingIncomeLoss.percentage * 100.0 + this.percentageUom);
            row.push(otherOperatingIncomeLoss.vendor );
            row.push(otherOperatingIncomeLoss.startMonth + this.timeUom);
            row.push(otherOperatingIncomeLoss.endMonth + this.timeUom);
            tableData.push({title: otherOperatingIncomeLoss.description, values: row});

        });
        return tableData;
  }

}
