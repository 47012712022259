import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexFill,
    ApexPlotOptions,
    ApexResponsive,
    ApexStates,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexYAxis,
    ChartComponent,
    NgApexchartsModule,
} from "ng-apexcharts";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {FormsModule} from "@angular/forms";
import {NzSkeletonComponent} from "ng-zorro-antd/skeleton";

type ApexXAxis = {
    type?: "category" | "datetime" | "numeric";
    categories?: any;
    labels?: {
        style?: {
            colors?: string | string[];
            fontSize?: string;
        };
    };
};

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis | ApexYAxis[];
    title: ApexTitleSubtitle;
    labels: string[];
    stroke: any; // ApexStroke
    dataLabels: any; // ApexDataLabels
    fill: ApexFill;
    tooltip: ApexTooltip;
    plotOptions: ApexPlotOptions; // Додаємо plotOptions
    subtitle: ApexTitleSubtitle; // Додаємо subtitle
    colors: ApexColorStop; // Додаємо colors
    states: ApexStates; // Додаємо states
    responsive: ApexResponsive[];
};

@Component({
    selector: 'app-column-with-data-labels',
    standalone: true,
    imports: [CommonModule, NgApexchartsModule, NzSwitchModule, FormsModule, NzSkeletonComponent],
    templateUrl: './column-with-data-labels.component.html',
    styleUrls: ['./column-with-data-labels.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnWithDataLabelsComponent {
    @ViewChild("chart") chart: ChartComponent;
    @Input() title: string;
    @Input() chartOptions: Partial<ChartOptions>;
    @Input() isSwitchEmitter: boolean;
    @Output() switchEmitter = new EventEmitter();
    isMonthly: boolean = false;

    onClick() {
        this.isMonthly = !this.isMonthly;
        this.switchEmitter.emit(this.isMonthly)
    }
}
