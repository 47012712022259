import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ApexChart, ApexNonAxisChartSeries, ApexResponsive, ChartComponent, NgApexchartsModule} from "ng-apexcharts";
import {NzSwitchComponent} from "ng-zorro-antd/switch";
import {NzSkeletonComponent} from "ng-zorro-antd/skeleton";

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: any;
};


@Component({
    selector: 'app-simple-pie',
    standalone: true,
    imports: [CommonModule, NgApexchartsModule, NzSwitchComponent, NzSkeletonComponent],
    templateUrl: './simple-pie.component.html',
    styleUrls: ['./simple-pie.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimplePieComponent {
    @ViewChild("chart") chart: ChartComponent;
    @Input() title: string;
    @Input() chartOptions: Partial<ChartOptions>;
    @Input() isSwitchEmitter: boolean;
    @Output() switchEmitter = new EventEmitter();
    isBrand: boolean = false;

    onClick() {
        this.isBrand = !this.isBrand;
        this.switchEmitter.emit(this.isBrand)
    }
}
