<!---->


<!---->
<div style="background: #ECECEC;padding:30px; margin-bottom: 40px">
    <div nz-row [nzGutter]="12">
        <div nz-col [nzSpan]="12">
            <nz-card nzTitle="Operating Profit">
                <h2 style="color: #1d39c4; margin-left: 5%">502K, Operating Profit EBIT</h2>
            </nz-card>
        </div>
        <div nz-col [nzSpan]="12">
            <nz-card nzTitle="Total Investment">
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center"><h3
                        style="color: #0b685b">Total Investment Required</h3>
                    <h3 style="color: #0d8c7f"><b>-106518</b> Investments & CAPEX</h3>
                </div>
            </nz-card>
        </div>
    </div>
</div>
<!---->
<app-column-with-data-labels [chartOptions]="chartOptionsColumnEl1" [title]="chartOptionsColumnTitleEl1"></app-column-with-data-labels>

<app-simple-pie  [chartOptions]="chartOptionsEl2" [title]="chartOptionsColumnTitleEl2"></app-simple-pie>

<!--<div style=" background: #eaebeb; padding: 20px">-->
<!--    <nz-card nzTitle="Unnamed">-->
<!--        <div nz-card-grid [ngStyle]="gridStyle">-->
<!--            <p style="font-weight: 600">{{ formattedNumber(4488384) }}</p>-->
<!--            <p style="color: gray">Net Sales Revenue</p>-->
<!--        </div>-->
<!--        <div nz-card-grid [ngStyle]="gridStyle">-->
<!--            <p style="font-weight: 600">{{ formattedNumber(28.8) }}, %</p>-->
<!--            <p style="color: gray">Gross Margin, %</p>-->

<!--        </div>-->
<!--        <div nz-card-grid [ngStyle]="gridStyle">-->
<!--            <p style="font-weight: 600">{{ formattedNumber(43530) }},</p>-->
<!--            <p style="color: gray">Volume</p>-->
<!--        </div>-->
<!--        <div nz-card-grid [ngStyle]="gridStyle">-->
<!--            <p style="font-weight: 600">{{ formattedNumber(169) }}</p>-->
<!--            <p style="color: gray">Max Monthly Customers</p>-->
<!--        </div>-->
<!--        <div nz-card-grid [ngStyle]="gridStyle">-->
<!--            <p style="font-weight: 600">{{ formattedNumber(3603) }}</p>-->
<!--            <p style="color: gray">Monthly Orders/Customers</p>-->
<!--        </div>-->
<!--        <div nz-card-grid [ngStyle]="gridStyle">-->
<!--            <p style="font-weight: 600">{{ formattedNumber(1245.58) }}</p>-->
<!--            <p style="color: gray">Average Order</p>-->
<!--        </div>-->
<!--        <div nz-card-grid [ngStyle]="gridStyle">-->
<!--            <p style="font-weight: 600">{{ formattedNumber(8.9) }}, %</p>-->
<!--            <p style="color: gray">Operating Profit Margin - EBIT to Net Sales, %</p>-->
<!--        </div>-->
<!--        <div nz-card-grid [ngStyle]="gridStyle">-->
<!--            <p style="font-weight: 600">{{ formattedNumber(401060) }}</p>-->
<!--            <p style="color: gray">Operating Profit EBIT</p>-->
<!--        </div>-->
<!--        <div nz-card-grid [ngStyle]="gridStyle">-->
<!--            <p style="font-weight: 600">{{ formattedNumber(-134854) }}</p>-->
<!--            <p style="color: gray">Investment & CAPEX</p>-->
<!--        </div>-->
<!--    </nz-card>-->
<!--</div>-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--<app-line-with-data-labels [chartOptions]="chartOptions" [title]="chartOptionsTitle"></app-line-with-data-labels>-->
<!--&lt;!&ndash;&ndash;&gt;-->

<!--<app-plate [columns]="getColumnsConfig()" [data]="generateData()" [setStyle]="setStyle"-->
<!--           [tableTitle]="tableTitle"></app-plate>-->
<!--<app-plate [columns]="getColumnsConfig2()" [data]="generateData2()" [setStyle]="setStyle2"-->
<!--           [tableTitle]="tableTitle2"></app-plate>-->
<!--<app-plate [columns]="getColumnsConfig3()" [data]="generateData3()"-->
<!--           [tableTitle]="tableTitle3" [calculateTotals]="calculateTotals3"></app-plate>-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--<app-line-with-data-labels [chartOptions]="chartOptions2" [title]="chartOptionsTitle2"></app-line-with-data-labels>-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--<app-plate [columns]="getColumnsConfig4()" [data]="generateData4()" [setStyle]="setStyle4"-->
<!--           [tableTitle]="tableTitle4"></app-plate>-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--<app-column-with-data-labels [chartOptions]="chartOptionsColumnNegative" [title]="chartOptionsColumnNegativeTitle"></app-column-with-data-labels>-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--<app-plate [columns]="getColumnsConfig5()" [data]="generateData5()" [setStyle]="setStyle5"-->
<!--           [tableTitle]="tableTitle5"></app-plate>-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--<app-line-with-data-labels [chartOptions]="initChartData3" [title]="chartOptionsTitle3"></app-line-with-data-labels>-->

<!--&lt;!&ndash;&ndash;&gt;-->
<!--<app-column-with-data-labels [chartOptions]="chartOptionsColumnMarketingBudget" [title]="columnMarketingBudgetTitle"></app-column-with-data-labels>-->
<!--<nz-card nzType="inner" style="text-align: center;" >-->
<!--    <h2>26.1K Marketing Budget</h2>-->
<!--</nz-card>-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--<app-column-with-data-labels [chartOptions]="chartOptionsColumnExpensesByYear" [title]="columnExpensesByYearTitle"></app-column-with-data-labels>-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--<app-column-with-data-labels [chartOptions]="chartOptionsVendor" [title]="vendorTitle"></app-column-with-data-labels>-->
<!--&lt;!&ndash;&ndash;&gt;-->




