<div style="display: flex; justify-content: center; align-items: center; margin-top: 40px  ">
    <h3 style="color: #007bbd"><i>{{ title }}</i></h3>
</div>
<nz-skeleton [nzActive]="true" [nzLoading]="!chartOptions" [nzTitle]="true" [nzParagraph]="{ rows: 3 }">
    <div *ngIf="chartOptions">
        <div id="chart">
            <nz-switch style="align-self: flex-start" *ngIf="isSwitchEmitter" (click)="onClick()"
                       nzCheckedChildren="Brand"
                       nzUnCheckedChildren="Product"></nz-switch>
            <apx-chart *ngIf="chartOptions"
                       [series]="chartOptions?.series"
                       [chart]="chartOptions?.chart"
                       [labels]="chartOptions?.labels"
                       [responsive]="chartOptions?.responsive"
            ></apx-chart>
        </div>
    </div>
</nz-skeleton>
