import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild,} from '@angular/core';
import * as jspreadsheet from 'jspreadsheet-ce';
import {NgIf} from "@angular/common";
import {NzSkeletonComponent} from "ng-zorro-antd/skeleton";

@Component({
    selector: 'app-plate',
    standalone: true,
    templateUrl: './plate.component.html',
    styleUrls: ['./plate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        NzSkeletonComponent
    ]
})
export class PlateComponent implements OnInit {
    @ViewChild('spreadsheet', {static: true}) spreadsheet: ElementRef;

    @Input() tableTitle: string;
    @Input() years: number[] | string[];
    @Input() rowData: { title: string; values: number[]; formula?: (values: number[]) => number[] }[];
    @Input() includeColumnTotals = false;
    @Input() includeRowTotals = false;
    @Input() columnWidth = 120;


    ngOnInit() {
        const columns: jspreadsheet.Column[] = [
            {
                type: 'text',
                title: ' ',
                width: 200,
                align: 'left'
            }, // Тип "text" для першого стовпця
            ...this.years?.map((year) => ({
                type: 'text',
                title: year.toString(),
                width: this.columnWidth,
                align: 'left',
                wordWrap: true,
            } as jspreadsheet.Column)), // Явний тип "numeric" для років
            ...(this.includeRowTotals ? [{
                type: 'numeric',
                title: 'Total',
                width: this.columnWidth
            } as jspreadsheet.Column] : []), // Тип "numeric" для стовпця Total
        ];

        if (!this.rowData) {
            return;
        }

        const data = this.rowData.map((row) => {
            const computedValues = row.formula
                ? row.formula(row.values)
                : row.values;
            const total = computedValues.reduce((sum, value) => sum + value, 0);
            return this.includeRowTotals ? [row.title, ...computedValues, total?.toFixed(2)] : [row.title, ...computedValues];
        });

        if (this.includeColumnTotals) {
            const columnTotals = this.years.map((_, colIndex) =>
                data.reduce((sum, row) => sum + (+row[colIndex + 1] || 0), 0)
            );
            const rowTotalSum = this.includeRowTotals
                ? columnTotals.reduce((sum, value) => sum + value, 0)
                : null;

            data.push([
                'Total',
                ...columnTotals,
                ...(this.includeRowTotals ? [rowTotalSum] : []),
            ]);
        }

        jspreadsheet(this.spreadsheet.nativeElement, {
            data,
            columns,
            updateTable: (instance, cell, col, row) => {
                if (col === 0) {
                    cell.style.textAlign = 'left';
                }
                if (
                    (this.includeColumnTotals && row === data.length - 1) ||
                    (this.includeRowTotals && col === columns.length - 1 && row >= 0)
                ) {
                    cell.classList.add('total-cell');
                }
            },
        });
    }
}
