/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * (tsType: Omit<Trashbin, \'id\'>, schemaOptions: { title: \'NewTrashbin\', exclude: [ \'id\' ] })
 */
export interface NewTrashbin { 
  [key: string]: object | any;


    createdAt?: string;
    updatedAt?: string;
    archivedrecord?: string | null;
    recordtype?: string;
    sourceobject?: string;
    isDeleted?: boolean | null;
    interfaceProcessFlag?: string;
}

