<div style="display: flex; justify-content: center; align-items: center; margin-top: 40px  ">
    <h3 style="color: #007bbd"><i>{{ title }}</i></h3>
</div>
<nz-skeleton [nzActive]="true" [nzLoading]="!chartOptions" [nzTitle]="true" [nzParagraph]="{ rows: 3 }">
    <div *ngIf="chartOptions">
        <div id="chart" class=".disable-scroll">
            <apx-chart *ngIf="chartOptions"
                       [series]="chartOptions.series"
                       [chart]="chartOptions.chart"
                       [xaxis]="chartOptions.xaxis"
                       [stroke]="chartOptions.stroke"
                       [colors]="chartOptions.colors"
                       [dataLabels]="chartOptions.dataLabels"
                       [legend]="chartOptions.legend"
                       [markers]="chartOptions.markers"
                       [grid]="chartOptions.grid"
                       [yaxis]="chartOptions.yaxis"
                       [title]="chartOptions.title"
                       [fill]="chartOptions.fill"
                       [tooltip]="chartOptions.tooltip"
                       [autoUpdateSeries]="true"
                       [responsive]="chartOptions?.responsive"
            ></apx-chart>
        </div>
    </div>
</nz-skeleton>
