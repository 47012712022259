<div class="main-assumptions">
    <div class="main-assumptions__container">
        <h2 class="main-assumptions__header">
            Main assumptions
        </h2>
        <hr>
        <div class="main-assumptions__body">
            <section class="main-assumptions__basic-info">
                <nz-card class="main-assumptions__basic-info__card">
                    <p><i>Currency: </i> <b>{{ plan?.businessplanSetting.currency }}</b></p>
                    <p><i>UOM: </i> <b>{{ plan?.businessplanSetting.UOM }}</b></p>
                    <p><i>Business Plan Period: </i> <b>{{ plan?.businessplanSetting.periods }}</b> <i> months </i></p>
                    <p><i>Inflation: </i> <b>{{ plan?.businessplanSetting.yearlyInflationRate }}</b> <i> % </i></p>
                    <p><i>Name of Consumers: </i>
                        <b>{{ plan?.businessplanSetting?.actualConsumer ?? "bp.details.consumersList.customers" | translate }}</b>
                    </p>
                </nz-card>
                <nz-card class="main-assumptions__basic-info__card">
                    <p><i>Monthly (Targeted Audience/Unregistered/Crowd/Traffic): </i>
                        <b>{{ plan?.aboutVisitorsCustomers.totalMonthlyCrowd }}</b></p>
                    <p><i>Monthly (Targeted Audience/Registered Users/Offline Visitors): </i>
                        <b>{{ plan?.aboutVisitorsCustomers.totalMonthlyVisitors }}</b></p>
                    <p><i>Monthly Conversion
                        of {{ plan?.businessplanSetting?.actualConsumer ?? "bp.details.consumersList.customers" | translate }}
                        That Buys : </i> <b>{{ plan?.aboutVisitorsCustomers.monthlyConversionUserThatBuys }}</b></p>
                    <p><i>Total
                        Monthly {{ plan?.businessplanSetting?.actualConsumer ?? "bp.details.consumersList.customers" | translate }}
                        : </i> <b>{{ plan?.aboutVisitorsCustomers.totalMonthlyCustomers }}</b></p>
                </nz-card>
            </section>
            <section class="main-assumptions__portfolio">
                <app-plate *ngIf="portfolioTableData" [rowData]="portfolioTableData" [years]="tHeads.PORTFOLIO_TABLE_HEADERS"
                           [tableTitle]="'Portfolio'">
                </app-plate>

                <div style="display: flex; justify-content: center; align-items: center; margin-top: 40px  ">
                    <h3 style="color: #007bbd"><i>{{ "Sales Acceleration" }}</i></h3>
                </div>
                <app-acceleration-trend-chart [values]="accelerationChartData?.trendData"
                 [periods]="accelarationPeriods" [trendType]="accelerationChartData?.trendType"></app-acceleration-trend-chart>

                <app-plate *ngIf="seasonalityTableData" [rowData]="seasonalityTableData"
                           [years]="tHeads.SEASONALITY_TABLE_HEADERS" [tableTitle]="'Seasonality'"
                           [columnWidth]="71">
                </app-plate>
                <app-plate *ngIf="headcountTableData" [rowData]="headcountTableData" [years]="tHeads.HEADCOUNT_TABLE_HEADERS"
                           [tableTitle]="'Headcount'" [columnWidth]="170">
                </app-plate>
                <app-plate *ngIf="discountTableData" [rowData]="discountTableData" [years]="tHeads.DISCOUNT_TABLE_HEADERS"
                           [tableTitle]="'Discount'" [columnWidth]="212">
                </app-plate>
                <app-plate *ngIf="otherCogsTableData" [rowData]="otherCogsTableData" [years]="tHeads.OTHER_COGS_TABLE_HEADERS"
                           [tableTitle]="'Other COGS'" [columnWidth]="170">
                </app-plate>
                <app-plate *ngIf="investmentsTableData" [rowData]="investmentsTableData"
                           [years]="tHeads.INVESTMENTS_TABLE_HEADERS" [tableTitle]="'Investments'"
                           [columnWidth]="212">
                </app-plate>
                <app-plate *ngIf="marketingTableData" [rowData]="marketingTableData" [years]="tHeads.MARKETING_TABLE_HEADERS"
                           [tableTitle]="'Marketing'" [columnWidth]="170">
                </app-plate>
                <app-plate *ngIf="operatingExpencesTableData" [rowData]="operatingExpencesTableData"
                           [years]="tHeads.OPERATING_EXPENSES_TABLE_HEADERS" [tableTitle]="'Operating expences'"
                           [columnWidth]="170">
                </app-plate>
                <app-plate *ngIf="rndTableData" [rowData]="rndTableData" [years]="tHeads.RND_TABLE_HEADERS"
                           [tableTitle]="'Research and Development Expedentures (RnD)'">
                </app-plate>
                <app-plate *ngIf="otherIncomeAndLossTableData" [rowData]="otherIncomeAndLossTableData"
                           [years]="tHeads.OTHER_INCOME_AND_LOSS_TABLE_HEADERS" [tableTitle]="'Other Operating Income & Loss'">
                </app-plate>
            </section>
        </div>
    </div>
</div>
