<app-main-assumptions [ideaId]="+businessPlanId"></app-main-assumptions>

<div class="years_slider">
    <nz-slider *ngIf="markYears?.min"
               nzRange
               [nzMin]="markYears.min"
               [nzMax]="markYears.max"
               [nzStep]="markYears.step"
               [nzMarks]="markYears.marks"
               [(ngModel)]="markYears.range">
    </nz-slider>
    <button *ngIf="markYears?.min && (appliedYearsRange[0] !== markYears.range[0] || appliedYearsRange[1] !== markYears.range[1])"
            style="width: 100px;" nz-button nzType="primary" type="submit" (click)="onApply()">
        <span *ngIf="!isLoadingAppliedYearsBtn">Apply</span>
        <nz-spin *ngIf="isLoadingAppliedYearsBtn" nzSimple [nzSize]="'small'"></nz-spin>
    </button>
</div>

<div style="background: #ECECEC;padding:30px; margin-bottom: 40px">
    <div nz-row [nzGutter]="12">
        <div nz-col [nzSpan]="12">
            <nz-card nzTitle="Operating Profit">
                <h2 style="color: #1d39c4; margin-left: 5%"><span>{{ operatingProfitEbitSum }}</span>, Operating Profit
                    EBIT</h2>
            </nz-card>
        </div>
        <div nz-col [nzSpan]="12">
            <nz-card nzTitle="Total Investment">
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center"><h3
                        style="color: #0b685b">Total Investment Required</h3>
                    <h3 style="color: #0d8c7f"><span><b>{{ totalInvestmentRequiredSum }}</b></span>, Investments & CAPEX
                    </h3>
                </div>
            </nz-card>
        </div>
    </div>
</div>

<app-column-with-data-labels *ngIf="chartOptionsColumnEl1" [isSwitchEmitter]="true" (switchEmitter)="el1Data($event)"
                             [chartOptions]="chartOptionsColumnEl1"
                             [title]="chartOptionsColumnTitleEl1"></app-column-with-data-labels>

<app-simple-pie *ngIf="chartOptionsEl2" [chartOptions]="chartOptionsEl2" [isSwitchEmitter]="true"
                (switchEmitter)="el2Data($event)" [title]="chartOptionsColumnTitleEl2"></app-simple-pie>

<div *ngIf="monthlyCustomersMaxFactValue" style=" background: #eaebeb; padding: 20px">
    <nz-card [nzTitle]="titleEl3">
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber(totalNetSales) }}</p>
            <p style="color: gray">Net Sales Revenue</p>
        </div>
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber(totalGrossProfit) }}</p>
            <p style="color: gray">Gross profit</p>

        </div>
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber((totalGrossProfit / totalNetSales) * 100, 2) }}, %</p>
            <p style="color: gray">Gross Margin, %</p>

        </div>
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber(totalVolume) }}</p>
            <p style="color: gray">Volume</p>
        </div>
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber(monthlyCustomersMaxFactValue) }}</p>
            <p style="color: gray">Max Monthly Customers</p>
        </div>
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber(totalMonthlyCustomers) }}</p>
            <p style="color: gray">Monthly Orders/Customers</p>
        </div>
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber(totalAverageCustomers, 2) }}</p>
            <p style="color: gray">Average Order</p>
        </div>
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber(operatingProfitEbitSumNum) }}</p>
            <p style="color: gray">Operating Profit EBIT</p>
        </div>
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber((operatingProfitEbitSumNum / totalNetSales) * 100, 2) }},
                %</p>
            <p style="color: gray">Operating Profit Margin - EBIT to Net Sales, %</p>
        </div>
        <div nz-card-grid [ngStyle]="gridStyle">
            <p style="font-weight: 600">{{ formattedNumber(Math.abs(totalInvestmentAndCapex)) }}</p>
            <p style="color: gray">Investment & CAPEX</p>
        </div>
    </nz-card>
</div>

<app-line-with-data-labels *ngIf="summarizedOperatingProfitByPeriod" [chartOptions]="chartOptionsEl4"
                           [title]="titleEl4"></app-line-with-data-labels>


<app-plate *ngIf="rowDataEl5" [rowData]="rowDataEl5" [years]="yearsEl5" [tableTitle]="titleEl5"
           [includeRowTotals]="true"></app-plate>

<!--<app-plate [columns]="getColumnsConfig3()" [data]="generateData3()"-->
<!--           [tableTitle]="tableTitle3" [calculateTotals]="calculateTotals3"></app-plate>-->
<!--<app-wbr-pivot #pivot [toolbar]="false" height="300px"></app-wbr-pivot>-->


<app-line-with-data-labels *ngIf="summarizedNetSalesByPeriod" [chartOptions]="chartOptionsEl7"
                           [title]="titleEl7"></app-line-with-data-labels>
<!---->
<app-plate *ngIf="rowDataEl8" [rowData]="rowDataEl8" [years]="yearsEl8" [tableTitle]="titleEl8"
           [includeRowTotals]="true"></app-plate>
<!---->
<app-column-with-data-labels *ngIf="summarizedInvestmentAndCapexByYear" [chartOptions]="chartOptionsColumnEl9"
                             [title]="titleEl9"></app-column-with-data-labels>
<!---->
<!--<app-plate  [title]="titleEl10" ></app-plate>-->

<app-line-with-data-labels *ngIf="summarizedMonthlyCustomersByPeriod" [chartOptions]="chartOptionsEl11"
                           [title]="titleEl11"></app-line-with-data-labels>

<app-column-with-data-labels *ngIf="summarizedMarketingByYear" [chartOptions]="chartOptionsColumnEl12"
                             [isSwitchEmitter]="true" (switchEmitter)="el12Data($event)"
                             [title]="titleEl12"></app-column-with-data-labels>

<app-column-with-data-labels *ngIf="summarizedNetSalesByYear" [chartOptions]="chartOptionsColumnEl13"
                             [isSwitchEmitter]="true" (switchEmitter)="el13Data($event)"
                             [title]="titleEl13"></app-column-with-data-labels>

<app-column-with-data-labels *ngIf="summarizedNetSalesByItemName" [chartOptions]="chartOptionsColumnEl14"
                             [title]="titleEl14"></app-column-with-data-labels>

<br/>
<br/>
<br/>



