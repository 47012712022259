/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserWithRelations } from './userWithRelations';


/**
 * (tsType: UserloginWithRelations, schemaOptions: { includeRelations: true })
 */
export interface UserloginWithRelations { 
  [key: string]: object | any;


    id?: number;
    createdAt?: string;
    updatedAt?: string;
    loginAt?: string;
    ipAddress?: string | null;
    interfaceProcessFlag?: string;
    isdeleted?: number | null;
    loginuserid?: number;
    loginUserProfile?: UserWithRelations;
}

