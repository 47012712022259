<div style="display: flex; justify-content: center; align-items: center; margin-top: 40px ">
    <h3 style="color: #007bbd"><i>{{ title }}</i></h3>
</div>
<nz-skeleton [nzActive]="true" [nzLoading]="!chartOptions" [nzTitle]="true" [nzParagraph]="{ rows: 3 }">
    <div *ngIf="chartOptions">
        <div id="chart" class="disable-scroll">
            <nz-switch *ngIf="isSwitchEmitter" (click)="onClick()" nzCheckedChildren="Monthly"
                       nzUnCheckedChildren="Yearly"></nz-switch>
            <apx-chart *ngIf="chartOptions"
                       [series]="chartOptions?.series"
                       [chart]="chartOptions?.chart"
                       [yaxis]="chartOptions?.yaxis"
                       [xaxis]="chartOptions?.xaxis"
                       [labels]="chartOptions?.labels"
                       [stroke]="chartOptions?.stroke"
                       [title]="chartOptions?.title"
                       [plotOptions]="chartOptions?.plotOptions"
                       [subtitle]="chartOptions?.subtitle"
                       [colors]="chartOptions?.colors"
                       [states]="chartOptions?.states"
                       [dataLabels]="chartOptions?.dataLabels"
                       [fill]="chartOptions?.fill"
                       [tooltip]="chartOptions?.tooltip"
                       [responsive]="chartOptions?.responsive"
            ></apx-chart>
        </div>
    </div>
</nz-skeleton>


