import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzFormModule} from "ng-zorro-antd/form";
import {NzCardModule} from "ng-zorro-antd/card";
import {FormsModule} from "@angular/forms";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {NzWaveDirective} from "ng-zorro-antd/core/wave";
import {NzSliderComponent, NzSliderModule} from "ng-zorro-antd/slider";
import {NzButtonComponent, NzButtonModule} from "ng-zorro-antd/button";
import * as _ from "lodash";

import {
    ColumnWithDataLabelsComponent
} from "@shared/charts-and-graphs/charts-and-graphs-models/column-with-data-labels/column-with-data-labels.component";
import {
    LineWithDataLabelsComponent
} from "@shared/charts-and-graphs/charts-and-graphs-models/line-with-data-labels/line-with-data-labels.component";
import {SimplePieComponent} from "@shared/charts-and-graphs/charts-and-graphs-models/simple-pie/simple-pie.component";
import {PlateComponent} from "@shared/charts-and-graphs/charts-and-graphs-models/plate/plate.component";
import {MainAssumptionsComponent} from './charts-and-graphs-models/main-assumptions/main-assumptions.component';
import {Pnldata, PnldataControllerService, PnldataFilter1} from "@shared/sdk";
import {WebdatarocksPivotModule} from "@webdatarocks/ngx-webdatarocks";

@Component({
    selector: 'app-charts-and-graphs',
    standalone: true,
    imports: [
        CommonModule,
        ColumnWithDataLabelsComponent,
        LineWithDataLabelsComponent,
        SimplePieComponent,
        PlateComponent,
        NzFormModule,
        NzCardModule,
        WebdatarocksPivotModule,
        MainAssumptionsComponent,
        NzSliderComponent,
        FormsModule,
        NzSliderModule,
        NzButtonComponent,
        NzSpinComponent,
        NzWaveDirective,
        NzButtonModule,
    ],
    templateUrl: './charts-and-graphs.component.html',
    styleUrls: ['./charts-and-graphs.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})


export class ChartsAndGraphsComponent implements OnInit, AfterViewInit {
    @Input() businessPlanId: string;
    initPnlData: Pnldata[];
    pnlData: Pnldata[];
    markYears: { min: number, max: number, step: number, range: number[], marks: {} }
    appliedYearsRange: number[];
    isLoadingAppliedYearsBtn: boolean;
    netSalesItems: { itemname: string, totalFactvalue: number }[] = [];
    netSalesBrand: { itemname: string, totalFactvalue: number }[] = [];
    operatingProfitEbitSum: string = '  . . .  ';
    operatingProfitEbitSumNum: number;
    totalInvestmentRequiredSum: string = '  . . .  ';
    summarizedNetSalesByPeriod: { factdate: string, totalFactvalue: number }[] = [];
    summarizedNetSalesByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedNetSalesByItemName: { itemname: string, totalFactvalue: number }[] = [];
    summarizedOperatingProfitByItemName: { itemname: string, totalFactvalue: number }[] = [];
    summarizedGrossProfitByPeriod: { factdate: string, totalFactvalue: number }[] = [];
    summarizedOperatingProfitByPeriod: { factdate: string, totalFactvalue: number }[] = [];
    summarizedMonthlyCustomersByPeriod: { factdate: string, totalFactvalue: number }[] = [];
    summarizedOperatingProfitByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedCogsByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedMarketingByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedMarketingByPeriod: { factdate: string, totalFactvalue: number }[] = [];
    summarizedStandardOpexByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedExpensesByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedExpensesByPeriod: { factdate: string, totalFactvalue: number }[] = [];
    summarizedHeadcountAndPayrollByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedOtherIncomeLossByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedRndByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedFixedAssetsDepreciationYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedInvestmentAndCapexByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedOperatingProfitMarginByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedGrossProfitByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedVolumeByYear: { factdate: string, totalFactvalue: number }[] = [];
    summarizedGrossMarginByPeriod: { factdate: string, totalFactvalue: number }[] = [];
    summarizedGrossMarginByYear: { factdate: string, totalFactvalue: number }[] = [];
    chartOptionsColumnTitleEl1: string;
    chartOptionsColumnEl1: any;
    chartOptionsColumnEl9: any;
    chartOptionsColumnEl12: any;
    chartOptionsColumnEl13: any;
    chartOptionsColumnEl14: any;
    chartOptionsColumnTitleEl2: string;
    titleEl3: string;
    titleEl5: string;
    titleEl6: string;
    titleEl7: string;
    titleEl8: string;
    titleEl9: string;
    titleEl10: string;
    titleEl11: string;
    titleEl12: string;
    titleEl13: string;
    titleEl14: string;
    chartOptionsEl2: any;
    chartOptionsEl4: any;
    chartOptionsEl7: any;
    chartOptionsEl11: any;
    titleEl4: any;
    totalNetSales: number;
    totalGrossProfit: number;
    totalVolume: number;
    monthlyCustomersMaxFactValue: number;
    totalMonthlyCustomers: number;
    totalAverageCustomers: number;
    totalInvestmentAndCapex: number;
    rowDataEl5: { title: string; values: number[] }[];
    rowDataEl8: { title: string; values: number[] }[];
    yearsEl5: number[] = [];
    yearsEl8: number[] = [];
    colors = [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#00D9E9",
        "#FF66C3"
    ];
    protected readonly Math = Math;

    constructor(private pnlService: PnldataControllerService,
                private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    gridStyle = {
        width: '33.3%',
        textAlign: 'center',

    };

    formattedNumber(number: number, maximumFractionDigits: number = 0): string {
        return number?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits});
    }

    ngOnInit(): void {
        this.getPnlData()
    }

    getPnlData() {
        const filter: PnldataFilter1 = {
            where: {
                and: [
                    {
                        or: [
                            {
                                isdeleted: 0
                            },
                            {
                                isdeleted: null
                            }
                        ]
                    },
                    {
                        ideaid: this.businessPlanId
                    }
                ]
            },
            fields: {
                factdate: true,
                itemcode: true,
                ideaid: true,
                createdbyid: true,
                factvalue: true,
                currency: true,
                uom: true,
                description: true,
                pnlrow: true,
                itemname: true,
                updatedAt: true,
            },
            include: [{
                relation: "mdmPnlRowRecord",
                scope: {
                    offset: 0,
                    limit: 100,
                    skip: 0,
                    fields: {
                        level0: true,
                        level1: true,
                        level2: true,
                        level3: true,
                        level4: true,
                    }
                },

            }]
        };

        this.pnlService.pnldataControllerFind(JSON.stringify(filter) as any)
            .subscribe({
                next: (value) => {
                    value = value?.map(item => {
                        return {
                            ...item,
                            period: item.factdate?.slice(0, 7),
                            yearly: item.factdate?.slice(0, 4),
                            brand: item.itemname.split('##')[0]
                        };
                    })
                    this.pnlData = value
                    this.initPnlData = value
                },
                complete: () => {
                    if (this.initPnlData && Array.isArray(this.initPnlData) && this.initPnlData.length > 0) {
                        this.transformPnlData();
                        const years = _.map(this.initPnlData, d => {
                            if (d['yearly'] !== undefined) {
                                return _.toNumber(d['yearly']);
                            }
                            return null;
                        }).filter(year => year !== null);

                        this.markYears = {min: 0, max: 0, step: 1, range: [], marks: {}};

                        if (years.length > 0) {
                            this.markYears.min = _.min(years);
                            this.markYears.max = _.max(years);
                            this.markYears.step = _.chain(years).sort().uniq().thru(arr => arr.length > 1 ? arr[1] - arr[0] : 1).value();
                            this.markYears.marks = _.zipObject(years, years.map(String));
                            this.markYears.range = [this.markYears.min, this.markYears.max];
                            this.appliedYearsRange = [this.markYears.min, this.markYears.max]
                        }
                    }
                }

            })

    }

    /////////////////////////////////////////globalFilterYear
    globalFilterYear() {
        // this.pnlData.slice()
    };


    //==================================Calculation=========================================


    transformPnlData() {
        let netSalesFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level3: "1111. Net Sales Revenue"
            }
        })

        let operatingProfitEbitFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level0: "1. EBIT-Operating Profit"
            }
        })

        let volumeFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level0: "2.Volume of Services"
            }
        })

        let totalInvestmentRequiredSum = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level0: "4. Investment & CAPEX"
            }
        })

        let grossProfitFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level2: "111.Gross Profit"
            }
        });

        let monthlyCustomersFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level2: "Monthly Customers",
            },
            itemname: "Total Product"
        })

        let investmentAndCapexFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level0: "4. Investment & CAPEX",
            },
        })

        let cogsFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level3: "1112.Cogs & Fees",
            },
        })

        let marketingFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level2: "112. Marketing",
            },
        })

        let standardOpexFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level2: "123. Standard Opex",
            },
        })

        let headcountAndPayrollFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level2: "124. Headcount & Payroll",
            },
        })

        let otherIncomeLossFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level2: "125.Other Income & Loss",
            },
        })

        let rndFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level2: "141.RnD,Education,Healthcare",
            },
        })

        let fixedAssetsDepreciationFull = _.filter(this.pnlData, {
            mdmPnlRowRecord: {
                level2: "151.Fixed Assets Depreciation",
            },
        })


        //---------------------- Profit Ebit --------------------------------
//todo need to simplify
        const operatingProfitEbit = _.sumBy(operatingProfitEbitFull, 'factvalue');
        this.operatingProfitEbitSumNum = _.sumBy(operatingProfitEbitFull, 'factvalue');
        this.operatingProfitEbitSum = operatingProfitEbit >= 1000000 ? (operatingProfitEbit / 1000000)?.toFixed(1) + 'M' :
            operatingProfitEbit >= 1000 ? (operatingProfitEbit / 1000)?.toFixed(1) + 'K' : operatingProfitEbit?.toFixed(2);


        const totalInvestmentRequired = _.sumBy(totalInvestmentRequiredSum, 'factvalue');
        this.totalInvestmentRequiredSum = Math.abs(totalInvestmentRequired) >= 1_000_000
            ? (totalInvestmentRequired / 1_000_000).toFixed(1) + 'M'
            : Math.abs(totalInvestmentRequired) >= 1_000
                ? (totalInvestmentRequired / 1_000).toFixed(1) + 'K'
                : totalInvestmentRequired.toFixed(2);

        // const netSalesPickItemnameAndFactValue = _.map(netSalesFull, item => _.pick(item, ['itemname', 'factvalue']));
        //-----------------------NET SALES--------------------------------

        const netSalesGroupByProduct = _.groupBy(netSalesFull, 'itemname')
        this.netSalesItems = _.map(netSalesGroupByProduct, (items, product) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {itemname: product, totalFactvalue};
        });

        ///todo simple pie
        const netSalesGroupByBrand = _.groupBy(netSalesFull, 'brand')
        this.netSalesBrand = _.map(netSalesGroupByBrand, (items, product) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {itemname: product, totalFactvalue};
        });

        const netSalesGroupByPeriod = _.groupBy(netSalesFull, 'period')
        this.summarizedNetSalesByPeriod = _.map(netSalesGroupByPeriod, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        this.totalNetSales = _.sumBy(this.summarizedNetSalesByPeriod, 'totalFactvalue')

        const netSalesGroupByYear = _.groupBy(netSalesFull, 'yearly')
        this.summarizedNetSalesByYear = _.map(netSalesGroupByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });


        //-----------------------GROSS PROFIT--------------------------------
        const grossProfitGroupByPeriod = _.groupBy(grossProfitFull, 'period')
        this.summarizedGrossProfitByPeriod = _.map(grossProfitGroupByPeriod, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        this.totalGrossProfit = _.sumBy(this.summarizedGrossProfitByPeriod, 'totalFactvalue')

        const grossProfitGroupByYear = _.groupBy(grossProfitFull, 'yearly')
        this.summarizedGrossProfitByYear = _.map(grossProfitGroupByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });


        //-----------------------GROSS MARGIN--------------------------------
        const grossMarginMergedByPeriod = _.unionBy(this.summarizedGrossProfitByPeriod, this.summarizedNetSalesByPeriod, 'factdate');

        this.summarizedGrossMarginByPeriod = grossMarginMergedByPeriod.map(entry => {
            const value1 = _.find(this.summarizedNetSalesByPeriod, {factdate: entry.factdate})?.totalFactvalue || 0;
            const value2 = _.find(this.summarizedGrossProfitByPeriod, {factdate: entry.factdate})?.totalFactvalue || 0;
            return {
                factdate: entry.factdate,
                totalFactvalue: value2 / value1,
            };
        });


        const grossMarginMergedByYear = _.unionBy(this.summarizedGrossProfitByYear, this.summarizedNetSalesByYear, 'factdate');
        this.summarizedGrossMarginByYear = grossMarginMergedByYear.map(entry => {
            const value1 = _.find(this.summarizedNetSalesByYear, {factdate: entry.factdate})?.totalFactvalue || 0;
            const value2 = _.find(this.summarizedGrossProfitByYear, {factdate: entry.factdate})?.totalFactvalue || 0;
            return {
                factdate: entry.factdate,
                totalFactvalue: value2 / value1,
            };
        });


        //-----------------------VOLUME--------------------------------
        this.totalVolume = _.sumBy(volumeFull, 'factvalue');
        const grossVolumeByYear = _.groupBy(volumeFull, 'yearly')
        this.summarizedVolumeByYear = _.map(grossVolumeByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });


        //-----------------------Monthly Customers--------------------------------
        const monthlyCustomersMaxPnl = _.maxBy(monthlyCustomersFull, 'factvalue') as Pnldata
        this.monthlyCustomersMaxFactValue = monthlyCustomersMaxPnl.factvalue;
        this.totalMonthlyCustomers = _.sumBy(monthlyCustomersFull, 'factvalue');
        this.totalAverageCustomers = _.meanBy(monthlyCustomersFull, 'factvalue');

        const monthlyCustomersByPeriod = _.groupBy(monthlyCustomersFull, 'period')
        this.summarizedMonthlyCustomersByPeriod = _.map(monthlyCustomersByPeriod, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });


        //-----------------------Investment And Capex--------------------------------
        this.totalInvestmentAndCapex = _.sumBy(investmentAndCapexFull, 'factvalue');

        //-----------------------Operating PROFIT--------------------------------
        const operatingProfitGroupByPeriod = _.groupBy(operatingProfitEbitFull, 'period')
        this.summarizedOperatingProfitByPeriod = _.map(operatingProfitGroupByPeriod, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        const operatingProfitGroupByYear = _.groupBy(operatingProfitEbitFull, 'yearly')
        this.summarizedOperatingProfitByYear = _.map(operatingProfitGroupByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        const mergedByYearOperatingProfitToNetSales = _.unionBy(this.summarizedOperatingProfitByYear, this.summarizedNetSalesByYear, 'factdate');
        this.summarizedOperatingProfitMarginByYear = mergedByYearOperatingProfitToNetSales.map(entry => {
            const value1 = _.find(this.summarizedOperatingProfitByYear, {factdate: entry.factdate})?.totalFactvalue || 0;
            const value2 = _.find(this.summarizedNetSalesByYear, {factdate: entry.factdate})?.totalFactvalue || 0;
            return {
                factdate: entry.factdate,
                totalFactvalue: value2 / value1,
            };
        });

        //-----------------------Cogs--------------------------------
        const cogsByYear = _.groupBy(cogsFull, 'yearly')
        this.summarizedCogsByYear = _.map(cogsByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        //-----------------------Marketing--------------------------------
        const marketingByYear = _.groupBy(marketingFull, 'yearly')
        this.summarizedMarketingByYear = _.map(marketingByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        const marketingByPeriod = _.groupBy(marketingFull, 'period')
        this.summarizedMarketingByPeriod = _.map(marketingByPeriod, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });
        //-----------------------StandardOpex--------------------------------

        const standardOpexByYear = _.groupBy(standardOpexFull, 'yearly')
        this.summarizedStandardOpexByYear = _.map(standardOpexByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        //-----------------------HeadcountAndPayroll--------------------------------

        const headcountAndPayrollByYear = _.groupBy(headcountAndPayrollFull, 'yearly')
        this.summarizedHeadcountAndPayrollByYear = _.map(headcountAndPayrollByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        //-----------------------OtherIncomeLossByYear--------------------------------

        const otherIncomeLossByYear = _.groupBy(otherIncomeLossFull, 'yearly')
        this.summarizedOtherIncomeLossByYear = _.map(otherIncomeLossByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        //-----------------------RndByYear--------------------------------

        const rndByYear = _.groupBy(rndFull, 'yearly')
        this.summarizedRndByYear = _.map(rndByYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        //-----------------------OtherIncomeLossByYear--------------------------------

        const fixedAssetsDepreciationYear = _.groupBy(fixedAssetsDepreciationFull, 'yearly')
        this.summarizedFixedAssetsDepreciationYear = _.map(fixedAssetsDepreciationYear, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });

        //-----------------------investment & capex by year--------------------------------

        const investmentAndCapex = _.groupBy(investmentAndCapexFull, 'yearly')
        this.summarizedInvestmentAndCapexByYear = _.map(investmentAndCapex, (items, date) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {factdate: date, totalFactvalue};
        });
        //-----------------------expenses by vendor--------------------------------

        const netSalesByItemName = _.groupBy(netSalesFull, 'itemname')
        this.summarizedNetSalesByItemName = _.map(netSalesByItemName, (items, itemname) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {itemname, totalFactvalue};
        });

        const operatingProfitByItemName = _.groupBy(operatingProfitEbitFull, 'itemname')
        this.summarizedOperatingProfitByItemName = _.map(operatingProfitByItemName, (items, itemname) => {
            const totalFactvalue = _.sumBy(items, 'factvalue');
            return {itemname, totalFactvalue};
        });


        this.insertData()
    }


    insertData() {
        ////////////////////////////// El1 //////////////////////////////
        this.el1Data()

        ////////////////////////////// El2 //////////////////////////////
        this.el2Data()

        ////////////////////////////// El3 //////////////////////////////
        this.titleEl3 = 'Main indicators'

        ////////////////////////////// El4 //////////////////////////////
        this.titleEl4 = 'Operating Profit EBIT and Cumulative Operating Profit by Year'
        const normalData = this.summarizedOperatingProfitByPeriod.map(value => parseFloat(value.totalFactvalue?.toFixed(2)));
        const cumulativeData = normalData.reduce((acc, curr, index) => {
            acc.push((acc[index - 1] || 0) + curr);
            return acc;
        }, []);
        this.chartOptionsEl4 = {
            series: [
                {
                    name: "Operating Profit EBIT",
                    data: normalData,
                },
                {
                    name: "Cumulative Operating Profit",
                    data: cumulativeData
                }
            ],
            chart: {
                height: 350,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: true,
                    tools: {
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true
                    },
                    autoSelected: 'zoom'
                }
            },
            colors: ["#77B6EA", "#545454"],
            stroke: {
                curve: "smooth"
            },
            title: {
                text: "Operating Profit Over Time",
                align: "left"
            },
            grid: {
                borderColor: "#e7e7e7",
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5
                }
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: this.summarizedOperatingProfitByPeriod.map(value => value.factdate),
                title: {
                    text: "Month"
                },
            },
            yaxis: {
                min: this.getAdjustedMinMax([...cumulativeData.map(value => value), ...normalData.map(value => value)]).min,
                max: this.getAdjustedMinMax([...cumulativeData.map(value => value), ...normalData.map(value => value)]).max,

                labels: {
                    formatter: (val) =>
                        (val < 0 ? "-" : "") +
                        (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                            Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                                Math.abs(val).toFixed(2))
                }
                ,
                title: {
                    text: "Operating Profit"
                }
            },
            legend: {
                position: "top",
                horizontalAlign:
                    "right",
                floating:
                    true,
                offsetY:
                    -25,
                offsetX:
                    -5
            }
        }
        ;

        ////////////////////////////// El5 ////////////////////////////// plate
        //volume
        this.titleEl5 = 'Net Sales vs Operating Profit';
        this.summarizedVolumeByYear.map(value => this.yearsEl5.push(+value.factdate));
        const volumeObj: { title: string, values: number[] } = {
            title: 'Volume',
            values: this.summarizedVolumeByYear.map(value => Math.floor(value.totalFactvalue))
        };
        const netSalesObj: { title: string, values: number[] } = {
            title: 'Net Sales Revenue',
            values: this.summarizedNetSalesByYear.map(value => Math.floor(value.totalFactvalue))
        };

        const grossProfitObj: { title: string, values: number[] } = {
            title: 'Gross Profit',
            values: this.summarizedGrossProfitByYear.map(value => Math.floor(value.totalFactvalue))
        };

        const grossMarginObj: { title: string, values: number[] } = {
            title: 'Gross Margin',
            values: this.summarizedGrossMarginByYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        const operatingProfitEBITObj: { title: string, values: number[] } = {
            title: 'Operating Profit EBIT',
            values: this.summarizedOperatingProfitByPeriod.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        const operatingProfitMarginObj: { title: string, values: number[] } = {
            title: 'Operating Profit Margin',
            values: this.summarizedOperatingProfitMarginByYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        const investmentAndCapexObj: { title: string, values: number[] } = {
            title: 'Investment And Capex',
            values: this.summarizedInvestmentAndCapexByYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };


        this.rowDataEl5 = [volumeObj, netSalesObj, grossProfitObj, grossMarginObj, operatingProfitEBITObj, operatingProfitMarginObj]

        ////////////////////////////// El6 ////////////////////////////// plate
        this.titleEl6 = 'Visitors to Customers conversion'

        ////////////////////////////// El7 //////////////////////////////
        this.titleEl7 = 'Sales vs Expenses'
        const mergedByPeriodOperatingProfitToNetSales = _.unionBy(this.summarizedNetSalesByPeriod, this.summarizedOperatingProfitByPeriod, 'factdate');
        this.summarizedExpensesByPeriod = mergedByPeriodOperatingProfitToNetSales.map(entry => {
            const value1 = _.find(this.summarizedNetSalesByPeriod, {factdate: entry.factdate})?.totalFactvalue || 0;
            const value2 = _.find(this.summarizedOperatingProfitByPeriod, {factdate: entry.factdate})?.totalFactvalue || 0;
            return {
                factdate: entry.factdate,
                totalFactvalue: value1 - value2,
            };
        });

        //todo mergedByYearOperatingProfitToNetSales has used at calculation
        const mergedByExpensesVendor = _.unionBy(this.summarizedNetSalesByItemName, this.summarizedOperatingProfitByItemName, 'itemname');
        const expensesByVendor = mergedByExpensesVendor.map(entry => {
            const value1 = _.find(this.summarizedNetSalesByItemName, {itemname: entry.itemname})?.totalFactvalue || 0;
            const value2 = _.find(this.summarizedOperatingProfitByItemName, {itemname: entry.itemname})?.totalFactvalue || 0;
            return {
                x: entry.itemname,
                y: value1 - value2,
            };
        });

        const mergedByYearOperatingProfitToNetSales = _.unionBy(this.summarizedNetSalesByYear, this.summarizedOperatingProfitByYear, 'factdate');
        this.summarizedExpensesByYear = mergedByYearOperatingProfitToNetSales.map(entry => {
            const value1 = _.find(this.summarizedNetSalesByYear, {factdate: entry.factdate})?.totalFactvalue || 0;
            const value2 = _.find(this.summarizedOperatingProfitByYear, {factdate: entry.factdate})?.totalFactvalue || 0;
            return {
                factdate: entry.factdate,
                totalFactvalue: value1 - value2,
            };
        });

        this.chartOptionsEl7 = {
            series: [
                {
                    name: "Net Sales Revenue",
                    data: this.summarizedNetSalesByPeriod.map(value => parseFloat(value.totalFactvalue?.toFixed(2))),
                    color: "#1E90FF"  // Синій колір для лінії
                },
                {
                    name: "Expenses (Cogs + Marketing + Standard Opex + Other Income Loss + RnD)",
                    data: this.summarizedExpensesByPeriod.map(value => parseFloat(value.totalFactvalue?.toFixed(2))),
                    color: "#FF6347"  // Червоний колір для лінії
                }
            ],

            chart: {
                height: 350,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: true,
                    tools: {
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true
                    },
                    autoSelected: 'zoom'
                }
            },
            colors: ["#77B6EA", "#545454"],
            stroke: {
                curve: "smooth"
            },
            title: {
                text: "Operating Profit Over Time",
                align: "left"
            },
            grid: {
                borderColor: "#e7e7e7",
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5
                }
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: this.summarizedExpensesByPeriod.map(value => value.factdate),
                title: {
                    text: "Month"
                }
            },
            yaxis: {
                min: this.getAdjustedMinMax([...this.summarizedExpensesByPeriod.map(value => value.totalFactvalue), ...this.summarizedNetSalesByPeriod.map(value => value.totalFactvalue)]).min,
                max: this.getAdjustedMinMax([...this.summarizedExpensesByPeriod.map(value => value.totalFactvalue), ...this.summarizedNetSalesByPeriod.map(value => value.totalFactvalue)]).max,
                title: {
                    text: "Net Sales Revenue"
                },
                labels: {
                    formatter: (val) =>
                        (val < 0 ? "-" : "") +
                        (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                            Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                                Math.abs(val).toFixed(2))
                }
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5
            }
        };

        ////////////////////////////// El8 //////////////////////////////plate
        this.titleEl8 = 'Detailed Pnl and Investments required'

        this.summarizedVolumeByYear.map(value => this.yearsEl8.push(+value.factdate));


        const cogsObj: { title: string, values: number[] } = {
            title: 'Cogs',
            values: this.summarizedCogsByYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        const marketingObj: { title: string, values: number[] } = {
            title: 'Marketing',
            values: this.summarizedCogsByYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        const standardOpexObj: { title: string, values: number[] } = {
            title: 'Standard Opex',
            values: this.summarizedStandardOpexByYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        const headcountAndPayrollObj: { title: string, values: number[] } = {
            title: 'Headcount And Payroll',
            values: this.summarizedHeadcountAndPayrollByYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        const otherIncomeLossObj: { title: string, values: number[] } = {
            title: 'Other Income Loss',
            values: this.summarizedOtherIncomeLossByYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        const rndObj: { title: string, values: number[] } = {
            title: 'RnD',
            values: this.summarizedRndByYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        const fixedAssetsDepreciationObj: { title: string, values: number[] } = {
            title: 'Fixed Assets Depreciation',
            values: this.summarizedFixedAssetsDepreciationYear.map(value => Number(value.totalFactvalue.toFixed(2)))
        };

        ///todo EBIDTA = Ebit Operating Profit - 151.Fixed Assets Depreciation


        this.rowDataEl8 = [volumeObj, netSalesObj, cogsObj, marketingObj, standardOpexObj, headcountAndPayrollObj, otherIncomeLossObj, rndObj, fixedAssetsDepreciationObj, operatingProfitEBITObj, investmentAndCapexObj]

        ////////////////////////////// El9 //////////////////////////////////////graphic investment
        this.titleEl9 = 'Investments Required';
        this.chartOptionsColumnEl9 = {
            series: [
                {
                    name: "Investment And Capex",
                    type: "column",
                    data: this.summarizedInvestmentAndCapexByYear.map(value => parseFloat(value.totalFactvalue?.toFixed(2)))
                },
            ],
            chart: {
                height: 350,
                type: "line",
                stacked: false // Вимикаємо складання колон
            },
            stroke: {
                width: [0, 0, 4] // Встановлюємо товщину для кожного типу серії
            },
            dataLabels: {
                enabledOnSeries: [0, 1], // Увімкнені мітки для стовпців
                enabled: true,
                formatter: (val) =>
                    (val < 0 ? "-" : "") +
                    (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                        Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                            Math.abs(val).toFixed(2))
            },
            labels: this.summarizedInvestmentAndCapexByYear.map(value => new Date(value.factdate).getTime()),
            xaxis: {
                type: "datetime"
            },
            yaxis: [
                {
                    title: {
                        text: "Investment And Capex"
                    },
                    min: this.getAdjustedMinMax([...this.summarizedInvestmentAndCapexByYear.map(value => value.totalFactvalue),
                        ...this.summarizedInvestmentAndCapexByYear.map(value => value.totalFactvalue)]).min,
                    max: this.getAdjustedMinMax([...this.summarizedInvestmentAndCapexByYear.map(value => value.totalFactvalue),
                        ...this.summarizedInvestmentAndCapexByYear.map(value => value.totalFactvalue)]).max,
                    labels: {
                        formatter: (val) =>
                            (val < 0 ? "-" : "") +
                            (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                                Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                                    Math.abs(val).toFixed(2))
                    }
                },
            ],
            tooltip: {
                shared: true,
                intersect: false
            }
        };
        ////////////////////////////// El10 //////////////////////////////////////Investment Details Split
        this.titleEl10 = 'Investment Details Split'

        ////////////////////////////// El11 //////////////////////////////////////monthly visitors/users

        this.titleEl11 = 'Maximum Monthly Visitors/Users'
        this.chartOptionsEl11 = {
            series: [
                {
                    name: "Maximum Monthly Visitors/Users",
                    data: this.summarizedMonthlyCustomersByPeriod.map(value => parseFloat(value.totalFactvalue?.toFixed(2))),
                    color: "#1E90FF"  // Синій колір для лінії
                },
            ],

            chart: {
                height: 350,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: true,
                    tools: {
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true
                    },
                    autoSelected: 'zoom'
                }
            },
            colors: ["#77B6EA", "#545454"],
            stroke: {
                curve: "smooth"
            },
            title: {
                text: "Operating Profit Over Time",
                align: "left"
            },
            grid: {
                borderColor: "#e7e7e7",
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5
                }
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: this.summarizedMonthlyCustomersByPeriod.map(value => value.factdate),
                title: {
                    text: "Month"
                }
            },
            yaxis: {
                title: {
                    text: "Customers/Users"
                },
                min: this.getAdjustedMinMax([...this.summarizedMonthlyCustomersByPeriod.map(value => value.totalFactvalue)]).min,
                max: this.getAdjustedMinMax([...this.summarizedMonthlyCustomersByPeriod.map(value => value.totalFactvalue)]).max,
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5
            }
        };

        ////////////////////////////// El12 //////////////////////////////////////marketing
        this.el12Data()
        ////////////////////////////// El13 //////////////////////////////////////expenses by year

        this.el13Data()

        ////////////////////////////// El14 //////////////////////////////////////

        this.chartOptionsColumnEl14 =

            {
                series: [
                    {
                        name: "Expenses",
                        data: expensesByVendor.sort((a, b) => b.y - a.y)
                    }
                ],
                chart: {
                    height: expensesByVendor.length * 40,
                    width: "100%",
                    type: "bar",
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
                plotOptions: {
                    bar: {
                        distributed: false,
                        horizontal: true,
                        // barHeight: "20px",
                        dataLabels: {
                            position: "top"
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: "start",
                    style: {
                        colors: ["#000"]
                    },
                    formatter: (val) =>
                        (val < 0 ? "-" : "") +
                        (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                            Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                                Math.abs(val).toFixed(2)),
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                colors: this.colors,
                states: {
                    normal: {
                        filter: {
                            type: "desaturate"
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: true,
                        filter: {
                            type: "darken",
                            value: 1
                        }
                    }
                },
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function (val, opts) {
                                return opts.w.globals.labels[opts.dataPointIndex];
                            }
                        }
                    }
                },
                title: {
                    text: "Expenses by VendorName",
                    offsetX: 15
                },
                yaxis: {
                    labels: {
                        show: true
                    }
                }
            };
    }


    ////////////////////////////////////////////////////////////////////
    el1Data(isMonthly: boolean = false) {
        const netSales = isMonthly ? this.summarizedNetSalesByPeriod : this.summarizedNetSalesByYear;
        const grossProfit = isMonthly ? this.summarizedGrossProfitByPeriod : this.summarizedGrossProfitByYear;
        const grossMargin = isMonthly ? this.summarizedGrossMarginByPeriod : this.summarizedGrossMarginByYear;
        this.chartOptionsColumnTitleEl1 = `Net Sales Revenue, Gross Profit and Gross Margin, % by ${isMonthly ? 'Month' : 'Year'}`
        this.chartOptionsColumnEl1 = {
            series: [
                {
                    name: "Net Sales Revenue",
                    type: "column",
                    data: netSales.map(value => parseFloat(value.totalFactvalue?.toFixed(2)))
                },
                {
                    name: "Gross Profit",
                    type: "column",
                    data: grossProfit.map(value => parseFloat(value.totalFactvalue?.toFixed(2)))
                },
                {
                    name: "Gross Margin",
                    type: "line",
                    data: grossMargin.map(value => parseFloat(value.totalFactvalue?.toFixed(2)) * 100)
                }
            ],
            chart: {
                height: 350,
                type: "line",
                stacked: false // Вимикаємо складання колон
            },
            stroke: {
                width: [0, 0, 4] // Встановлюємо товщину для кожного типу серії
            },
            dataLabels: {
                enabledOnSeries: [0, 1], // Увімкнені мітки для стовпців
                enabled: true,
                formatter: function (val: number) {
                    return val >= 1000000 ? (val / 1000000)?.toFixed(1) + 'M' :
                        val >= 1000 ? (val / 1000)?.toFixed(1) + 'K' : val?.toFixed(2);
                }
            },
            labels: netSales.map(value => new Date(value.factdate).getTime()),
            xaxis: {
                type: "datetime"
            },
            yaxis: [
                {
                    title: {
                        text: "Net Sales Revenue"
                    },
                    min: 'auto',
                    max: this.getAdjustedMinMax([...netSales.map(value => value.totalFactvalue),
                        ...grossProfit.map(value => value.totalFactvalue)]).max,
                    labels: {
                        formatter: (val) =>
                            (val < 0 ? "-" : "") +
                            (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                                Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                                    Math.abs(val).toFixed(2))
                    }
                },
                {
                    opposite: true,
                    title: {
                        text: "Gross Profit"
                    },
                    min: this.getAdjustedMinMax([...netSales.map(value => value.totalFactvalue),
                        ...grossProfit.map(value => value.totalFactvalue)]).min,
                    max: this.getAdjustedMinMax([...netSales.map(value => value.totalFactvalue),
                        ...grossProfit.map(value => value.totalFactvalue)]).max,
                    labels: {
                        formatter: (val) =>
                            (val < 0 ? "-" : "") +
                            (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                                Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                                    Math.abs(val).toFixed(2))
                    }
                },
                {
                    opposite: true,
                    title: {
                        text: "Gross Margin (%)"
                    },
                    min: this.getAdjustedMinMax(grossMargin.map(value => value.totalFactvalue), 120).min,
                    max: this.getAdjustedMinMax(grossMargin.map(value => value.totalFactvalue), 120).max,
                    labels: {
                        formatter: (val) => val?.toFixed(2) + '%'
                    }
                }
            ],
            tooltip: {
                shared: true,
                intersect: false
            }
        };
    };


    el2Data(isBrand: boolean = false) {
        this.chartOptionsColumnTitleEl2 = `Net Sales Revenue by ${isBrand ? 'Brand' : 'Product Category Brand'} `

        const data = isBrand ? this.netSalesBrand : this.netSalesItems;
        this.chartOptionsEl2 = {
            series: data.map(value => value.totalFactvalue),
            chart: {
                width: 580,
                type: "pie",
                toolbar: {
                    show: true,
                }
            },
            labels: data.map(value => value.itemname),

        };
    };


    el12Data(isMonthly: boolean = false) {
        this.titleEl12 = `Marketing Spent by ${isMonthly ? 'Month' : 'Year'}`
        const data = isMonthly ? this.summarizedMarketingByPeriod : this.summarizedMarketingByYear
        this.chartOptionsColumnEl12 = {
            series: [
                {
                    name: "Total Expenses",
                    type: "column",
                    data: data.map(value => parseFloat(value.totalFactvalue?.toFixed(2)))
                },
            ],
            chart: {
                height: 350,
                type: "line",
                stacked: false, // Вимикаємо складання колон
                toolbar: {
                    show: true,
                    tools: {
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true
                    },
                    autoSelected: 'zoom'
                },
            },
            stroke: {
                width: [0, 0, 4] // Встановлюємо товщину для кожного типу серії
            },
            dataLabels: {
                enabledOnSeries: [0, 1], // Увімкнені мітки для стовпців
                enabled: true,
                formatter: (val) =>
                    (val < 0 ? "-" : "") +
                    (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                        Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                            Math.abs(val).toFixed(2))
            },
            labels: data.map(value => new Date(value.factdate).getTime()),
            xaxis: {
                type: "datetime"
            },
            yaxis: [
                {
                    title: {
                        text: "Total Expenses"
                    },
                    min: this.getAdjustedMinMax(data.map(value => value.totalFactvalue)).min,
                    max: this.getAdjustedMinMax(data.map(value => value.totalFactvalue)).max,
                    labels: {
                        formatter: (val) =>
                            (val < 0 ? "-" : "") +
                            (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                                Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                                    Math.abs(val).toFixed(2))
                    }
                },
            ],
            tooltip: {
                shared: true,
                intersect: false
            }
        };

    }

    el13Data(isMonthly: boolean = false) {
        this.titleEl13 = `Total Expenses by ${isMonthly ? 'Month' : 'Year'}`

        const data = isMonthly ? this.summarizedExpensesByPeriod : this.summarizedExpensesByYear
        this.chartOptionsColumnEl13 = {
            series: [
                {
                    name: "Total Expenses",
                    type: "column",
                    data: data.map(value => parseFloat(value.totalFactvalue?.toFixed(2)))
                },
            ],
            chart: {
                height: 350,
                type: "line",
                stacked: false, // Вимикаємо складання колон
                toolbar: {
                    show: true,
                    tools: {
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true
                    },
                    autoSelected: 'zoom'
                },
            },
            stroke: {
                width: [0, 0, 4] // Встановлюємо товщину для кожного типу серії
            },
            dataLabels: {
                enabledOnSeries: [0, 1], // Увімкнені мітки для стовпців
                enabled: true,
                formatter: (val) =>
                    (val < 0 ? "-" : "") +
                    (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                        Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                            Math.abs(val).toFixed(2))
            },
            labels: data.map(value => new Date(value.factdate).getTime()),
            xaxis: {
                type: "datetime"
            },
            yaxis: [
                {
                    title: {
                        text: "Total Expenses"
                    },
                    min: this.getAdjustedMinMax(data.map(value => value.totalFactvalue)).min,
                    max: this.getAdjustedMinMax(data.map(value => value.totalFactvalue)).max,
                    labels: {
                        formatter: (val) =>
                            (val < 0 ? "-" : "") +
                            (Math.abs(val) >= 1_000_000 ? (Math.abs(val) / 1_000_000).toFixed(1) + "M" :
                                Math.abs(val) >= 1_000 ? (Math.abs(val) / 1_000).toFixed(1) + "K" :
                                    Math.abs(val).toFixed(2))
                    }
                },
            ],
            tooltip: {
                shared: true,
                intersect: false
            }
        };
    }

    ngAfterViewInit() {
        // // Знаходимо всі елементи з класом .disable-scroll
        // const elements = document.querySelectorAll('.disable-scroll');
        // console.log(elements)
        // elements.forEach((element: HTMLElement) => {
        //     // Додаємо обробник події для кожного елемента
        //     element.addEventListener(
        //         'wheel',
        //         (event) => {
        //             event.preventDefault();  // Блокуємо прокручування
        //         },
        //         {passive: false}  // Встановлюємо passive: false, щоб викликати preventDefault
        //     );
        // });
    }

    onApply() {
        this.isLoadingAppliedYearsBtn = true;
        this.pnlData = this.initPnlData.filter(item => {
            const year = parseInt(item['yearly'], 10);
            return year >= this.markYears.range[0] && year <= this.markYears.range[1];
        });

        const years = _.map(this.pnlData, d => {
            if (d['yearly'] !== undefined) {
                return _.toNumber(d['yearly']);
            }
            return null;
        }).filter(year => year !== null);
        //todo use microTask instead setTimeout
        setTimeout(() => {
            this.transformPnlData();
            this.isLoadingAppliedYearsBtn = false;
            this.appliedYearsRange = [_.min(years), _.max(years)];
        }, 0);
    }

    getAdjustedMinMax(data: number[], adjustmentFactor: number = 1.2): { min: number, max: number } {
        const minValue = Math.min(...data.map(value => value));
        const maxValue = Math.max(...data.map(value => value));

        return {
            min: minValue < 0 ? minValue * adjustmentFactor : minValue / adjustmentFactor,
            max: maxValue > 0 ? maxValue * adjustmentFactor : maxValue / adjustmentFactor,
        };
    }

}
